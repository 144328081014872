export default function() {
  /* 表格列头 */
  return [
    { label: "序号", type: "index", width: 80 },
    { label: "门店ID", prop: "store_id", width: 100 },
    { label: "开卡人openid", prop: "create_user", width: 200 },
    { label: "卡种", prop: "type", slots: "type", width: 100 },
    { label: "卡号", prop: "vip_number", width: 130 },
    { label: "姓名", prop: "user_name" },
    { label: "手机号", prop: "user_phone", width: 130 },
    { label: "性别", prop: "sex", width: 100 },
    { label: "余额", prop: "money", width: 100 },
    { label: "备注", prop: "msg" },
    {
      label: "开卡时间",
      prop: "create_time",
      width: 180,
      // slots: "state",
    },
    {
      label: "操作",
      prop: "operation",
      width: 100,
      slots: "operation",
      // fixed: "right",
    },
  ];
}
